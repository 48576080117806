<template>
  <div>
    <h1>Background</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col col="12">
          <v-radio-group
            :rules="required"
            label="Have you served in the Armed Forces?"
            :mandatory="false"
            v-model="veteran"
            :messages="['If yes, please send ACE transcript to SAU.']"
          >
            <v-radio label="Yes" value="Yes"></v-radio>
            <v-radio label="No" value="No"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="veteran === 'Yes'">
        <v-col col="12">
          <v-autocomplete
            :rules="required"
            v-model="veteranBranch"
            :items="branches"
            label="Select the branch in which you served."
            editable
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12" v-if="veteran === 'Yes'">
          <v-autocomplete
            :rules="required"
            v-model="veteranStat"
            :items="statuses"
            label="Select your current military status"
            editable
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-textarea
            outlined
            v-model="howHeard"
            :rules="required"
            label="How did you hear about Spring Arbor University?"
            name="hearAboutSau"
            maxlength="120"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" class="pb-0">
          <legend
            id="input-281"
            class="v-label theme--light"
            style="left: 0px; right: auto; position: relative;"
          >Select one or more of the following that apply to you:</legend>
          <div class="v-messages theme--light mb-4" role="alert"><div class="v-messages__wrapper"><div class="v-messages__message">The Federal Government and accrediting agencies request that colleges and universities describe the racial/ethnic background of students. To help us comply, please respond to the following questions.</div></div></div>
          <v-checkbox
            v-model="raceHi"
            label="Hispanic or Latino"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="raceAm"
            label="American Indian or Alaska Native"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="raceAs"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            label="Asian"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="raceBl"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            label="Black or African American"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="racePi"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            label="Native Hawaiian or Other Pacific Islander"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="raceWh"
            v-bind:true-value="'Y'"
            v-bind:false-value="'N'"
            label="White"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="veteran === 'Yes' || veteran === 'No'">
        <v-col col="12">
          <v-radio-group
            :rules="required"
            label="Have you ever been convicted of a misdemeanor or felony?"
            v-model="convict"
          >
            <v-radio label="Yes" value="Y"></v-radio>
            <v-radio label="No" value="N"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "globalApplication"
);
export default {
  name: "GlobalAppStep3",
  data: () => ({
    valid: "",
    branches: ["Air Force", "Army", "Coast Gaurd", "Marine Corps", "Navy"],
    statuses: ["Not Active", "Active", "Reserves"],
    nameRules: [
      v => !!v || "This field is required",
      v => (v && v.length <= 24) || "This field must be less than 24 characters."
    ],
    required: [v => !!v || "This field is required."],
    charecterCount: [
      v =>
        (v || "").length <= 200 || "Description must be 200 characters or less."
    ]
  }),
  // note about Vuex and forms https://vuex.vuejs.org/guide/forms.html
  computed: {
    ...mapState(["applicData", "currentStep"]),
    veteran: {
      get() {
        return this.applicData.veteran;
      },
      set(val) {
        this.updateApplicationDataProps({ veteran: val });
      }
    },
    veteranBranch: {
      get() {
        return this.applicData.veteranBranch;
      },
      set(val) {
        this.updateApplicationDataProps({ veteranBranch: val });
      }
    },
    veteranStat: {
      get() {
        return this.applicData.veteranStat;
      },
      set(val) {
        this.updateApplicationDataProps({ veteranStat: val });
      }
    },
    raceHi: {
      get() {
        return this.applicData.hispanic;
      },
      set(val) {
        this.updateApplicationDataProps({ hispanic: val });
      }
    },
    raceAm: {
      get() {
        return this.applicData.raceAmerican;
      },
      set(val) {
        this.updateApplicationDataProps({ raceAmerican: val });
      }
    },
    raceAs: {
      get() {
        return this.applicData.raceAsian;
      },
      set(val) {
        this.updateApplicationDataProps({ raceAsian: val });
      }
    },
    raceBl: {
      get() {
        return this.applicData.raceBlack;
      },
      set(val) {
        this.updateApplicationDataProps({ raceBlack: val });
      }
    },
    racePi: {
      get() {
        return this.applicData.racePacific;
      },
      set(val) {
        this.updateApplicationDataProps({ racePacific: val });
      }
    },
    raceWh: {
      get() {
        return this.applicData.raceWhite;
      },
      set(val) {
        this.updateApplicationDataProps({ raceWhite: val });
      }
    },
    howHeard: {
      get() {
        return this.applicData.learnAbout;
      },
      set(val) {
        this.updateApplicationDataProps({ learnAbout: val });
      }
    },
    convict: {
      get() {
        return this.applicData.crime;
      },
      set(val) {
        this.updateApplicationDataProps({ crime: val });
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("valid", { step: 3, result: true });
      } else {
        this.$emit("valid", { step: 3, result: false });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ...mapMutations(["updateApplicationDataProps", "setCurrentStep"]),
    ...mapActions["saveToServer"]
  }
};
</script>

<style scoped>
</style>
