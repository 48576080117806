<template>
  <div>
    <h1>Personal Information</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="fname"
            :rules="required"
            clearable
            label="First Name"
            name="fname"
            type="text"
            maxlength="24"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            validate-on-blur
            v-model="midname"
            clearable
            label="Middle Name"
            name="midname"
            type="text"
            maxlength="24"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="lname"
            :rules="required"
            clearable
            label="Last Name"
            name="lname"
            type="text"
            maxlength="32"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="suffix"
            :items="suffixItems"
            label="Suffix"
            editable
            clearable
            height
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="maidenName"
            clearable
            label="Maiden Name"
            name="maidenName"
            type="text"
            maxlength="32"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            validate-on-blur
            :rules="required"
            v-model="gender"
            :items="genders"
            item-text="txt"
            item-value="gender"
            label="Sex"
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="10">
          <v-text-field
            validate-on-blur
            v-model="address1"
            :rules="required"
            clearable
            label="Current Address"
            name="address1"
            type="text"
            maxlength="32"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox v-model="addLineTwo" label="Add Line 2"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" v-if="addLineTwo || address2">
          <v-text-field
            validate-on-blur
            v-model="address2"
            :rules="required"
            clearable
            label="Line 2"
            name="address2"
            type="text"
            maxlength="32"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="country"
            :rules="required"
            :items="countries"
            item-text="txt"
            item-value="ctry"
            label="Country"
            editable
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="city"
            :rules="required"
            clearable
            label="City"
            name="city"
            type="text"
            maxlength="32"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" v-if="country === 'USA'">
          <v-autocomplete
            v-model="state"
            :rules="required"
            :items="stateChoices"
            item-text="txt"
            item-value="st"
            label="State"
            editable
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" v-if="country === 'USA'">
          <v-text-field
            validate-on-blur
            v-model="zip"
            :rules="required"
            clearable
            label="Zip"
            name="zip"
            type="text"
            maxlength="10"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
              validate-on-blur
              :rules="birthdateRules"
              v-model="birthdateFormatted"
              label="Date of Birth (MM/DD/YYYY)"
              clearable
              type="text"
              name="birthdate"
              maxlength="10"
              ref="birthdate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="email"
            :rules="emailRules"
            clearable
            label="Email"
            name="email"
            type="text"
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            validate-on-blur
            v-model="phone"
            :rules="mobileRules"
            clearable
            label="Phone"
            name="phone"
            type="text"
            maxlength="12"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="birthCtry"
            :items="countries"
            item-text="txt"
            item-value="ctry"
            label="Birth Country"
            editable
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { parseDate,formatDate } from "@/utils";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "globalApplication"
);
export default {
  name: "GlobalAppStep2",
  data: () => ({
    valid: "",
    addLineTwo: false,
    suffixItems: ["II", "III", "IV"],
    genders: [{ txt: 'Male', gender: 'M' }, { txt: 'Female', gender: 'F' }],
    nameRules: [
      v => !!v || "This field is required.",
      v =>
        (v && v.length <= 24) || "This field must be less than 24 characters."
    ],
    emailRules: [
      v => !!v || "This field is required",
      v => /.+@.+\..+/.test(v) || "A valid email address is required"
    ],
    mobileRules: [
      v => !!v || "This field is required",
      v =>
        /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(v) ||
        "A valid 10 digit phone number is required"
    ],
    required: [v => !!v || "This field is required."],
    birthdateRules: [
      v => {
        return (!!v || "This field is required.")
      },
      v => {
        return ((!!v && !!(parseDate(v)) &&
            parseDate(v) > new Date((new Date().getFullYear() - 100) + "-01-01") &&
            parseDate(v) < new Date((new Date().getFullYear() - 10) + "-01-01")) || "Must be a birth date in the form month / day / year")
      }
    ]
  }),
  // note about Vuex and forms https://vuex.vuejs.org/guide/forms.html
  computed: {
    ...mapState(["applicData", "currentStep", "countries", "stateChoices"]),
    fname: {
      get() {
        return this.applicData.firstName;
      },
      set(val) {
        this.updateApplicationDataProps({ firstName: val });
      }
    },
    midname: {
      get() {
        return this.applicData.middleName;
      },
      set(val) {
        this.updateApplicationDataProps({ middleName: val });
      }
    },
    lname: {
      get() {
        return this.applicData.lastName;
      },
      set(val) {
        this.updateApplicationDataProps({ lastName: val });
      }
    },
    maidenName: {
      get() {
        return this.applicData.maidenName;
      },
      set(val) {
        this.updateApplicationDataProps({ maidenName: val });
      }
    },
    gender: {
      get() {
        return this.applicData.gender;
      },
      set(val) {
        this.updateApplicationDataProps({ gender: val });
      }
    },
    suffix: {
      get() {
        return this.applicData.nameSuffix;
      },
      set(val) {
        this.updateApplicationDataProps({ nameSuffix: val });
      }
    },
    address1: {
      get() {
        return this.applicData.currentAddress;
      },
      set(val) {
        this.updateApplicationDataProps({ currentAddress: val });
      }
    },
    address2: {
      get() {
        return this.applicData.line2;
      },
      set(val) {
        this.updateApplicationDataProps({ line2: val });
      }
    },
    country: {
      get() {
        return this.applicData.country;
      },
      set(val) {
        this.updateApplicationDataProps({ country: val });
      }
    },
    city: {
      get() {
        return this.applicData.city;
      },
      set(val) {
        this.updateApplicationDataProps({ city: val });
      }
    },
    state: {
      get() {
        return this.applicData.state;
      },
      set(val) {
        this.updateApplicationDataProps({ state: val });
      }
    },
    zip: {
      get() {
        return this.applicData.zip;
      },
      set(val) {
        this.updateApplicationDataProps({ zip: val });
      }
    },
    birthdateFormatted: {
      get() {
        return this.applicData.birthdate;
      },
      set(val) {
        let outDate = ''
        let parsedDate = parseDate(val);
        if (parsedDate != null && !isNaN(parsedDate.getTime())) {
//          console.log('date is good')
          outDate = formatDate(parsedDate);
//          console.log('date is formatted ' + outDate)
        }
        else {
//          console.log('date is bad')
          outDate = val;
        }
//        console.log('setting to ' + outDate);
        this.updateApplicationDataProps({ birthdate: outDate });
//        this.updateApplicationDataProps({ birthdate: val });
      }
    },
    email: {
      get() {
        return this.applicData.email;
      },
      set(val) {
        this.updateApplicationDataProps({ email: val });
      }
    },
    phone: {
      get() {
        return this.applicData.phone;
      },
      set(val) {
        this.updateApplicationDataProps({ phone: val });
      }
    },
    birthCtry: {
      get() {
        return this.applicData.birthCountry;
      },
      set(val) {
        this.updateApplicationDataProps({ birthCountry: val });
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("valid", { step: 2, result: true });
      } else {
        this.$emit("valid", { step: 2, result: false });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ...mapMutations(["updateApplicationDataProps", "setCurrentStep"]),
    ...mapActions["saveToServer"]
  }
};
</script>
