<template>
  <div>
    <h1 class="mb-3">SAU Values Agreement</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <h4
        class="mb-3"
      >Please read the SAU values statement below. In order to apply to SAU you must agree to abide by SAU’s values.</h4>
      <p>I am confirming that I understand that biblical values guide the academic, social, and spiritual community of Spring Arbor University. I recognize that its programs are structured to foster growth for students in each of those areas. I am also stating that I agree to abide by the expectations, policies, and procedures described in university documents that have been derived from those values, including their descriptions of personal health, moral integrity, social consciousness, and Christian conduct.</p>
      <p>Accordingly, during the admission process, I commit to respond to all application materials truthfully and fully, disclosing items related to prior college attendance and my legal status in the community, including but not limited to: prior convictions of misdemeanors and felonies, probation/parole status, and sex offender registries. I understand that falsifying or not including required information in the application process is grounds for denial or revocation of admission to Spring Arbor University.</p>
      <span v-if="this.applicData.graduateFromSAU === 'Y'">
        <p>If admitted, while on any campus property or facilities or while involved in any University-related activities, I agree to abstain from alcoholic beverages, drugs, and tobacco as well as any illegal, immoral, or disruptive activities. I also understand that I may be held accountable (up to and including dismissal) for illegal acts or public behavior--not necessarily related to University activities--which violate what the University determines to be its values and expectations of conduct for Spring Arbor students.</p>
      </span>
      <v-checkbox
        :rules="required"
        v-model="agreeValues"
        v-bind:true-value="'Y'"
        v-bind:false-value="'N'"
        hide-details
        label="I agree to abide by SAU's values and certify that all of the information I've provided is correct."
      ></v-checkbox>
      <v-row>
        <v-col col="12" md="6">
          <v-text-field
           outlined
            class="mt-2"
            validate-on-blur
            v-model="ssNo"
            dense
            :rules="ssnRule"
            hint="Required to validate your identity."
            clearable
            label="Social Security"
            name="ssn"
            maxlength="11"
            @input="validate(), formatssn()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "globalApplication"
);
export default {
  name: "GlobalAppStep4",
  data: () => ({
    valid: "",
    ssnRule: [
      v => !!v || "This field is required.",
      v =>
        /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(v) ||
        "This must be a valid Social Security Number. This field is required."
    ],
    required: [v => !!v || "This field is required."],
    charecterCount: [
      v =>
        (v || "").length <= 200 || "Description must be 200 characters or less"
    ]
  }),
  // note about Vuex and forms https://vuex.vuejs.org/guide/forms.html
  computed: {
    ...mapState(["applicData", "currentStep"]),
    ssNo: {
      // the data service always return the ssNo as QQQ-QQ-QQQQ as a security precaution
      get() {
        return this.applicData.ssNo === "QQQ-QQ-QQQQ"
          ? ""
          : this.applicData.ssNo;
      },
      set(val) {
        this.updateApplicationDataProps({ ssNo: val });
      }
    },
    agreeValues: {
      get() {
        return this.applicData.agreeValues;
      },
      set(val) {
        this.updateApplicationDataProps({ agreeValues: val });
      }
    }
  },
  methods: {
    formatssn() {
      var val = this.ssNo.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        this.ssNo = val;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("valid", { step: 4, result: true });
      } else {
        this.$emit("valid", { step: 4, result: false });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ...mapMutations(["updateApplicationDataProps", "setCurrentStep"]),
    ...mapActions["saveToServer"]
  }
};
</script>

<style scoped>
</style>
