<template>
  <div>
    <h1>Academic Interest</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- <v-row>
        <v-col col="12" md="12">
          <v-radio-group
            :rules="required"
            label="Desired Degree Level"
            :mandatory="false"
            v-model="degLvl"
          >
            <v-radio v-if="campusTracks[1]" label="Non-Degree Grad" value="Non-Degree Grad"></v-radio>
                        <v-radio v-if="campusTracks[0]" label="Certificate" value="Certificate"></v-radio>
                        <v-radio v-if="campusTracks[3]" label="Bachelor's" value="Bachelors"></v-radio>
                        <v-radio v-if="campusTracks[2]" label="Master's" value="Graduate"></v-radio>
                        <v-radio v-if="campusTracks[4]" label="Doctorate" value="Doctorate"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col col="12" md="12">
          <v-autocomplete
            :rules="required"
            v-model="program"
            :items="filteredTraks"
            item-text="txt"
            item-value="txt"
            return-object
            label="Select program"
            target="#dropdown-example"
            editable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row
        :class="{
          hide:
            siteList.length < 2 ||
            (!siteList.includes('Online') &&
              !siteList.includes('Virtual Classroom')),
        }"
      >
        <v-col col="12" md="12">
          <v-radio-group
            @change="classTypeChange()"
            :rules="required"
            label="What classroom type do you prefer?"
            v-model="classTypeValue"
          >
            <v-radio
              :class="{ hide: !siteList.includes('Online') }"
              label="Online"
              value="Online"
            ></v-radio>
            <v-radio
              :class="{ hide: !siteList.includes('Virtual Classroom') }"
              label="Virtual Classroom"
              value="Virtual Classroom"
            ></v-radio>
            <v-radio label="Face-To-Face" value="Face-To-Face"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row
        :class="{
          hide:
            siteList.length > 1 ||
            !siteList.includes('Virtual Classroom') ||
            (siteList.length > 1 && !siteList.includes('Virtual Classroom')),
        }"
      >
        <v-col col="12" md="12">
          <p>This program is offered as a virtual classroom only</p>
        </v-col>
      </v-row>
      <v-row
        :class="{
          hide:
            siteList.length > 1 ||
            !siteList.includes('Online') ||
            (siteList.length > 1 && !siteList.includes('Online')),
        }"
      >
        <v-col col="12" md="12">
          <p>This program is offered online only</p>
        </v-col>
      </v-row>

      <v-row
        v-if="program && degLvl"
        :class="{ hide: campus === 'Online' || campus === 'Virtual Classroom' }"
      >
        <v-col col="12" md="12">
          <v-autocomplete
            v-if="classTypeValue === 'Face-To-Face'"
            :rules="required"
            v-model="campus"
            :items="program.campuses"
            item-text="campus"
            label="Select face-to-face location"
            target="#dropdown-example"
            editable
            clearable
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :class="{
                  hide:
                    item.campus == 'Online' ||
                    item.campus == 'Virtual Classroom',
                }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item.campus"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="program.concentrations.length > 0">
        <v-col col="12" md="12">
          <v-autocomplete
            :rules="required"
            v-model="concentration"
            :items="program.concentrations"
            item-text="concentration"
            label="Select a Concentration"
            target="#dropdown-example"
            editable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="program.teach_cert_types.length > 0">
        <v-col col="12" md="12">
          <v-autocomplete
            :rules="required"
            v-model="teachCertType"
            :items="program.teach_cert_types"
            item-text="teachCertType"
            label="What type of teaching certificate do you hold?"
            target="#dropdown-example"
            editable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="program.teach_cert_types.length > 0">
        <v-col cols="12" md="12">
          <v-text-field
            validate-on-blur
            v-model="teachCertName"
            clearable
            label="How does your name appear on your MI certification?"
            name="teachCertName"
            type="text"
            maxlength="30"
          ></v-text-field>
          <em
            >Note: If you are certified outside of MI, please provide a copy of
            your certificate to the admissions recruiter.</em
          >
        </v-col>
      </v-row>

      <v-row v-if="campus && degLvl">
        <v-col col="12" md="12">
          <v-autocomplete
            :rules="required"
            v-model="entryTerm"
            :items="startDates"
            label="Desired Start Date"
            target="#dropdown-example"
            item-text="txt"
            item-value="term"
            editable
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>

      <span v-if="graduateFromSAU != 'N'">
        <v-row v-if="degLvl === 'Bachelors' && entryTerm">
          <v-col col="12" md="12">
            <v-radio-group
              :rules="required"
              label="Which path did you (or will you) take to receive your high school diploma?"
              v-model="hsGradSrc"
            >
              <v-radio label="High School" value="highschool"></v-radio>
              <v-radio label="Home School" value="homeschool"></v-radio>
              <v-radio label="GED" value="ged"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!--TO DO: needs search for high schools - use vuetify autocomplete-->
        <v-row
          v-if="
            degLvl === 'Bachelors' && hsGradSrc === 'highschool' && !hsNotListed
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              no-data-text="No Search Results"
              v-model="hsSelection"
              :loading="hsSearchLoading"
              :items="filteredHs"
              item-text="text"
              item-value="value"
              :search-input.sync="hsSearch"
              clearable
              hide-details
              label="Name of high school that awarded your diploma"
              @input="hsChosen"
              :rules="required"
            >
              <template slot="no-data">
                <span></span>
              </template>
              <template slot="prepend-item">
                <div
                  @click="checkHsNotListed()"
                  tabindex="0"
                  aria-selected="false"
                  role="option"
                  class="v-list-item v-list-item--link theme--light"
                >
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      <strong>My high school is not listed</strong>
                    </div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            hsNotListed && degLvl === 'Bachelors' && hsGradSrc === 'highschool'
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              :rules="required"
              v-model="hsNotListed"
              label="My high school not listed"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row
          v-if="
            hsNotListed && degLvl === 'Bachelors' && hsGradSrc === 'highschool'
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              v-model="hsName"
              :rules="required"
              clearable
              label="Enter name of high school that awarded your diploma"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="degLvl === 'Bachelors' && hsGradSrc === 'ged'">
          <v-col col="12" md="12">
            <v-text-field
              validate-on-blur
              v-model="gedName"
              :rules="required"
              clearable
              label="Name listed on GED"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Show just Year -->
        <v-row
          v-if="
            (degLvl === 'Bachelors' && hsGradSrc === 'highschool') ||
            (degLvl === 'Bachelors' && hsGradSrc === 'homeschool')
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="hsGradYr"
              :items="gradYearRange"
              label="High School Graduation Year"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="degLvl === 'Bachelors' && hsGradSrc === 'ged'">
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="gedDate"
              :items="gradYearRange"
              label="Year GED awarded"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (degLvl === 'Bachelors' && hsGradSrc === 'highschool') ||
            (degLvl === 'Bachelors' && hsGradSrc === 'homeschool')
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              validate-on-blur
              v-model="hsDiplName"
              :rules="required"
              clearable
              label="Name on Diploma"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- College recieved degree -->
        <v-row
          v-if="
            (degLvl === 'Graduate' && entryTerm && !coll1NotListed) ||
            (program.ba === true && entryTerm && !coll1NotListed) ||
            (degLvl === 'Non-Degree Grad' && entryTerm && !coll1NotListed)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              :rules="required"
              no-data-text="No Search Results"
              v-model="collAttName1Selection"
              :loading="collAttLoading1"
              :items="collAttList1"
              :search-input.sync="collAttSearch1"
              item-text="txt"
              item-value="val"
              hide-details
              clearable
              label="Name of school that awarded your bachelor's degree"
              @input="collAtt1Chosen"
            >
              <template slot="no-data">
                <span></span>
              </template>
              <template slot="prepend-item">
                <div
                  @click="checkColl1NotListed()"
                  tabindex="0"
                  aria-selected="false"
                  role="option"
                  class="v-list-item v-list-item--link theme--light"
                >
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      <strong>My college is not listed</strong>
                    </div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (coll1NotListed && degLvl === 'Graduate') ||
            (coll1NotListed && program.ba === true) ||
            (coll1NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              :rules="required"
              v-model="coll1NotListed"
              label="My college is not listed"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (coll1NotListed && degLvl === 'Graduate') ||
            (coll1NotListed && program.ba === true) ||
            (coll1NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              v-model="collAttName1"
              :rules="required"
              clearable
              label="Enter name of school that awarded your bachelor's degree"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (degLvl === 'Graduate' && entryTerm) ||
            (program.ba === true && entryTerm) ||
            (degLvl === 'Non-Degree Grad' && entryTerm)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="collAttLastYear1"
              :items="gradYearRange"
              label="Graduation year"
              editable
              clearable
              :rules="required"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName1) ||
            (program.ba === true && collAttName1) ||
            (degLvl === 'Non-Degree Grad' && collAttName1)
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              v-model="collAttBalance1"
              v-bind:true-value="'Y'"
              v-bind:false-value="'N'"
              :label="'Check the box if you owe a balance to ' + collAttName1"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!-- Other Colleges attended 1 -->
        <v-row
          v-if="
            (degLvl === 'Graduate' &&
              entryTerm &&
              collAttName1 &&
              !coll2NotListed) ||
            (program.ba === true &&
              entryTerm &&
              collAttName1 &&
              !coll2NotListed) ||
            (degLvl === 'Non-Degree Grad' &&
              entryTerm &&
              collAttName1 &&
              !coll2NotListed)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              no-data-text="No Search Results"
              v-model="collAttName2Selection"
              :loading="collAttLoading2"
              :items="collAttList2"
              :search-input.sync="collAttSearch2"
              item-text="txt"
              item-value="val"
              hide-details
              clearable
              label="Add other colleges you attended."
              @input="collAtt2Chosen"
            >
              <template slot="no-data">
                <span></span>
              </template>
              <template slot="prepend-item">
                <div
                  @click="checkColl2NotListed()"
                  tabindex="0"
                  aria-selected="false"
                  role="option"
                  class="v-list-item v-list-item--link theme--light"
                >
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      <strong>My college is not listed</strong>
                    </div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (coll2NotListed && degLvl === 'Graduate') ||
            (coll2NotListed && program.ba === true) ||
            (coll2NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              :rules="required"
              v-model="coll2NotListed"
              label="My college is not listed"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (coll2NotListed && degLvl === 'Graduate') ||
            (coll2NotListed && program.ba === true) ||
            (coll2NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              v-model="collAttName2"
              :rules="required"
              clearable
              label="Enter name of school that awarded your bachelor's degree"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName2 && collAttName1) ||
            (program.ba === true && collAttName2 && collAttName1) ||
            (degLvl === 'Non-Degree Grad' && collAttName2 && collAttName1)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="collAttLastYear2"
              :items="gradYearRange"
              :label="'Last year you attended ' + collAttName2"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName2 && collAttName1) ||
            (program.ba === true && collAttName2 && collAttName1) ||
            (degLvl === 'Non-Degree Grad' && collAttName2 && collAttName1)
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              v-model="collAttBalance2"
              v-bind:true-value="'Y'"
              v-bind:false-value="'N'"
              :label="'Check the box if you owe a balance to ' + collAttName2"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!-- Other Colleges attended 2 -->
        <v-row
          v-if="
            (degLvl === 'Graduate' &&
              entryTerm &&
              collAttName2 &&
              !coll3NotListed) ||
            (program.ba === true &&
              entryTerm &&
              collAttName2 &&
              !coll3NotListed) ||
            (degLvl === 'Non-Degree Grad' &&
              entryTerm &&
              collAttName2 &&
              !coll3NotListed)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              no-data-text="No Search Results"
              v-model="collAttName3Selection"
              :loading="collAttLoading3"
              :items="collAttList3"
              :search-input.sync="collAttSearch3"
              item-text="txt"
              item-value="val"
              hide-details
              clearable
              label="Add other colleges you attended."
              @input="collAtt3Chosen"
            >
              <template slot="no-data">
                <span></span>
              </template>
              <template slot="prepend-item">
                <div
                  @click="checkColl3NotListed()"
                  tabindex="0"
                  aria-selected="false"
                  role="option"
                  class="v-list-item v-list-item--link theme--light"
                >
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      <strong>My college is not listed</strong>
                    </div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (coll3NotListed && degLvl === 'Graduate') ||
            (coll3NotListed && program.ba === true) ||
            (coll3NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              :rules="required"
              v-model="coll3NotListed"
              label="My college is not listed"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (coll3NotListed && degLvl === 'Graduate') ||
            (coll3NotListed && program.ba === true) ||
            (coll3NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              v-model="collAttName3"
              :rules="required"
              clearable
              label="Enter name of school that awarded your bachelor's degree"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName3 && collAttName2) ||
            (program.ba === true && collAttName3 && collAttName2) ||
            (degLvl === 'Non-Degree Grad' && collAttName3 && collAttName2)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="collAttLastYear3"
              :items="gradYearRange"
              :label="'Last year you attended ' + collAttName3"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName3 && collAttName2) ||
            (program.ba === true && collAttName3 && collAttName2) ||
            (degLvl === 'Non-Degree Grad' && collAttName3 && collAttName2)
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              v-model="collAttBalance3"
              :label="'Check the box if you owe a balance to ' + collAttName3"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!-- Other Colleges attended 3 -->
        <v-row
          v-if="
            (degLvl === 'Graduate' &&
              entryTerm &&
              collAttName3 &&
              !coll4NotListed) ||
            (program.ba === true &&
              entryTerm &&
              collAttName3 &&
              !coll4NotListed) ||
            (degLvl === 'Non-Degree Grad' &&
              entryTerm &&
              collAttName3 &&
              !coll4NotListed)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              no-data-text="No Search Results"
              v-model="collAttName4Selection"
              :loading="collAttLoading4"
              :items="collAttList4"
              :search-input.sync="collAttSearch4"
              item-text="txt"
              item-value="val"
              hide-details
              clearable
              label="Add other colleges you attended."
              @input="collAtt4Chosen"
            >
              <template slot="no-data">
                <span></span>
              </template>
              <template slot="prepend-item">
                <div
                  @click="checkColl4NotListed()"
                  tabindex="0"
                  aria-selected="false"
                  role="option"
                  class="v-list-item v-list-item--link theme--light"
                >
                  <div class="v-list-item__content">
                    <div class="v-list-item__title">
                      <strong>My college is not listed</strong>
                    </div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (coll4NotListed && degLvl === 'Graduate') ||
            (coll4NotListed && program.ba === true) ||
            (coll4NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              :rules="required"
              v-model="coll4NotListed"
              label="My college is not listed"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (coll4NotListed && degLvl === 'Graduate') ||
            (coll4NotListed && program.ba === true) ||
            (coll4NotListed && degLvl === 'Non-Degree Grad')
          "
        >
          <v-col col="12" md="12">
            <v-text-field
              v-model="collAttName4"
              :rules="required"
              clearable
              label="Enter name of school that awarded your bachelor's degree"
              type="text"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName4 && collAttName3) ||
            (program.ba === true && collAttName4 && collAttName3) ||
            (degLvl === 'Non-Degree Grad' && collAttName4 && collAttName3)
          "
        >
          <v-col col="12" md="12">
            <v-autocomplete
              v-model="collAttLastYear4"
              :items="gradYearRange"
              :label="'Last year you attended ' + collAttName4"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (degLvl === 'Graduate' && collAttName4 && collAttName3) ||
            (program.ba === true && collAttName4 && collAttName3) ||
            (degLvl === 'Non-Degree Grad' && collAttName4 && collAttName3)
          "
        >
          <v-col cols="12" md="12">
            <v-checkbox
              v-model="collAttBalance4"
              :label="'Check the box if you owe a balance to ' + collAttName4"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="degLvl === 'Doctorate' && entryTerm">
          <v-col col="12" md="12">
            <v-text-field
              validate-on-blur
              v-model="regNumber"
              :rules="required"
              clearable
              label="Registration Number"
              type="text"
              maxlength="12"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="degLvl === 'Doctorate' && entryTerm">
          <v-col col="12" md="12">
            <v-autocomplete
              :rules="required"
              v-model="regState"
              :items="stateChoices"
              item-text="txt"
              item-value="st"
              label="Registered State"
              editable
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </span>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import _ from "lodash";
import { gradYearRange } from "@/utils";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("globalApplication");
function initEntryTerms() {
  let currentMonth = new Date().getMonth();
  let entryTerms = [];
  let termList = [
    { sess: "SP", txt: "Spring/Summer" },
    { sess: "FA", txt: "Fall" },
  ];
  for (
    let yearVar = new Date().getFullYear();
    yearVar <= new Date().getFullYear() + 4;
    yearVar++
  ) {
    termList.forEach((termObj) => {
      entryTerms.push({
        term: termObj.sess + " " + yearVar,
        txt: termObj.txt + " " + yearVar,
      });
    });
  }
  if (currentMonth > 2 && currentMonth < 10) {
    entryTerms.shift();
    entryTerms.pop();
  } else if (currentMonth <= 2) {
    entryTerms.pop();
    entryTerms.pop();
  } else {
    entryTerms.shift();
    entryTerms.shift();
  }

  return entryTerms;
}
export default {
  name: "GlobalAppStep1",
  data: () => ({
    degLvlSet: 0,
    programSet: false,
    classTypeValue: "",
    siteList: [],
    valid: "",
    hsNotListed: false,
    hsSearch: null,
    hsSearchInitial: "",
    hsSelection: null,
    hsSearchLoading: false,
    hsDoSearch: true,
    gradYearRange: gradYearRange(),
    gedDateMenu: false,
    gedDateFormatted: "",
    coll1NotListed: false,
    collAttSearch1: null,
    collAttSearch1Initial: "",
    collAttName1Selection: null,
    collAttLastYear1Menu: false,
    collAttLoading1: false,
    collAttDoSearch1: true,
    collAttList1: [],

    coll2NotListed: false,
    collAttSearch2: null,
    collAttSearch2Initial: "",
    collAttName2Selection: null,
    collAttLastYear2Menu: false,
    collAttLoading2: false,
    collAttDoSearch2: true,
    collAttList2: [],

    coll3NotListed: false,
    collAttSearch3: null,
    collAttSearch3Initial: "",
    collAttName3Selection: null,
    collAttLastYear3Menu: false,
    collAttLoading3: false,
    collAttDoSearch3: true,
    collAttList3: [],

    coll4NotListed: false,
    collAttSearch4: null,
    collAttSearch4Initial: "",
    collAttName4Selection: null,
    collAttLastYear4Menu: false,
    collAttLoading4: false,
    collAttDoSearch4: true,
    collAttList4: [],

    regNumber: "",
    regState: "",
    nameRules: [
      (v) => !!v || "This field is required.",
      (v) =>
        (v && v.length <= 24) || "This field must be less than 24 characters.",
    ],
    required: [(v) => !!v || "This field is required."],
    charecterCount50: [
      (v) =>
        (v || "").length <= 50 || "Description must be 200 characters or less.",
    ],
    select: null,
    startDates: initEntryTerms(),
    filteredHs: [],
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
  }),
  // note about Vuex and forms https://vuex.vuejs.org/guide/forms.html
  computed: {
    //rules
    checkTrak() {
      let self = this;
      return [
        () => self.program.trak != "" || "Please select a program of interest.",
      ];
    },
    graduateFromSAU: {
      get() {
        return this.applicData.graduateFromSAU;
      },
      set(val) {
        this.updateApplicationDataProps({ graduateFromSAU: val });
      },
    },
    degLvl: {
      get() {
        //console.log(this.applicData.desiredDegreeLevel)
        return this.applicData.desiredDegreeLevel;
      },
      set(val) {
        this.updateApplicationDataProps({ desiredDegreeLevel: val });
      },
    },
    entryTerm: {
      get() {
        return this.applicData.whenEnroll;
      },
      set(val) {
        this.updateApplicationDataProps({ whenEnroll: val });
      },
    },
    program: {
      get() {
        let matchingTrak = _.filter(this.filteredTraks, {
          trak: this.applicData.track,
          nonDegree: (this.applicData.graduateFromSAU === "N") ? true : false
        });
        matchingTrak = matchingTrak[0]
        if (matchingTrak) {
          return matchingTrak;
        } else {
          return { trak: "", txt: "", campuses: [], ba: "", nonDegree: "" };
        }
      },
      set(val) {
        this.graduateFromSAU = (val.nonDegree === true) ? "N" : "Y"
        this.updateApplicationDataProps({ track: val.trak });
        this.loadTrackInfo({ track: val.trak });
      },
    },
    campus: {
      get() {
        if (this.program && this.program.campuses) {
          let matchingCampus = _.find(this.program.campuses, {
            campus: this.applicData.campus,
          });
          if (matchingCampus) {
            return matchingCampus.campus;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      set(val) {
        this.updateApplicationDataProps({ campus: val });
      },
    },
    concentration: {
      get() {
        return this.applicData.conc;
      },
      set(val) {
        this.updateApplicationDataProps({ conc: val });
      },
    },
    teachCertType: {
      get() {
        return this.applicData.teachCertType;
      },
      set(val) {
        this.updateApplicationDataProps({ teachCertType: val });
      },
    },
    teachCertName: {
      get() {
        return this.applicData.teachCertName;
      },
      set(val) {
        this.updateApplicationDataProps({ teachCertName: val });
      },
    },
    teachCertCrnt: {
      get() {
        return this.applicData.teachCertCrnt;
      },
      set(val) {
        this.updateApplicationDataProps({ teachCertCrnt: val });
      },
    },
    hsGradSrc: {
      get() {
        return this.applicData.highSchoolDiplomaType;
      },
      set(val) {
        this.updateApplicationDataProps({ highSchoolDiplomaType: val });
      },
    },
    highSchoolNumber: {
      get() {
        return this.applicData.highSchoolNumber;
      },
      set(val) {
        this.updateApplicationDataProps({ highSchoolNumber: val });
      },
    },
    hsName: {
      get() {
        return this.applicData.hsName;
      },
      set(val) {
        this.updateApplicationDataProps({ hsName: val });
      },
    },
    hsCity: {
      get() {
        return this.applicData.hsCity;
      },
      set(val) {
        this.updateApplicationDataProps({ hsCity: val });
      },
    },
    hsState: {
      get() {
        return this.applicData.hsState;
      },
      set(val) {
        this.updateApplicationDataProps({ hsState: val });
      },
    },
    hsCountry: {
      get() {
        return this.applicData.hsCountry;
      },
      set(val) {
        this.updateApplicationDataProps({ hsCountry: val });
      },
    },
    hsDiplName: {
      get() {
        return this.applicData.highSchoolDiplomaName;
      },
      set(val) {
        this.updateApplicationDataProps({ highSchoolDiplomaName: val });
      },
    },
    hsGradYr: {
      get() {
        return this.applicData.hsGradYear;
      },
      set(val) {
        this.updateApplicationDataProps({ hsGradYear: val });
      },
    },
    gedState: {
      get() {
        return this.applicData.GEDstate;
      },
      set(val) {
        this.updateApplicationDataProps({ GEDstate: val });
      },
    },
    gedDate: {
      get() {
        return this.applicData.GEDdate;
      },
      set(val) {
        this.updateApplicationDataProps({ GEDdate: val });
      },
    },
    gedName: {
      get() {
        return this.applicData.GEDname;
      },
      set(val) {
        this.updateApplicationDataProps({ GEDname: val });
      },
    },
    collAttCode1: {
      get() {
        return this.applicData.collAttCode1;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttCode1: val });
      },
    },
    collAttName1: {
      get() {
        return this.applicData.collAttName1;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttName1: val });
      },
    },
    collAttLastYear1: {
      get() {
        return this.applicData.collAttLastYear1;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttLastYear1: val });
      },
    },
    collAttBalance1: {
      get() {
        return this.applicData.collAttBalance1;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttBalance1: val });
      },
    },
    collAttCode2: {
      get() {
        return this.applicData.collAttCode2;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttCode2: val });
      },
    },
    collAttName2: {
      get() {
        return this.applicData.collAttName2;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttName2: val });
      },
    },
    collAttLastYear2: {
      get() {
        return this.applicData.collAttLastYear2;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttLastYear2: val });
      },
    },
    collAttBalance2: {
      get() {
        return this.applicData.collAttBalance2;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttBalance2: val });
      },
    },
    collAttCode3: {
      get() {
        return this.applicData.collAttCode3;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttCode3: val });
      },
    },
    collAttName3: {
      get() {
        return this.applicData.collAttName3;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttName3: val });
      },
    },
    collAttLastYear3: {
      get() {
        return this.applicData.collAttLastYear3;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttLastYear3: val });
      },
    },
    collAttBalance3: {
      get() {
        return this.applicData.collAttBalance3;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttBalance3: val });
      },
    },
    collAttCode4: {
      get() {
        return this.applicData.collAttCode4;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttCode4: val });
      },
    },
    collAttName4: {
      get() {
        return this.applicData.collAttName4;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttName4: val });
      },
    },
    collAttLastYear4: {
      get() {
        return this.applicData.collAttLastYear4;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttLastYear4: val });
      },
    },
    collAttBalance4: {
      get() {
        return this.applicData.collAttBalance4;
      },
      set(val) {
        this.updateApplicationDataProps({ collAttBalance4: val });
      },
    },
    ...mapState([
      "applicData",
      "currentStep",
      "campusTracks",
      "highSchoolSearchResult",
      "collegeSearchResult",
      "stateChoices",
    ]),
    filteredTraks() {
      // the server call might not have returned yet
      if (!this.campusTracks || this.campusTracks.length === 0) {
        return [];
      }
      /* if (this.degLvl === "Graduate") {
        return this.campusTracks[2].traks;
      } else if (this.degLvl === "Non-Degree Grad") {
        return this.campusTracks[1].traks;
      } else if (this.degLvl === "Bachelors") {
        return this.campusTracks[3].traks;
      } else if (this.degLvl === "Doctorate") {
        return this.campusTracks[4].traks;
      } else if (this.degLvl === "Certificate") {
        return this.campusTracks[0].traks;
      } else { */
      let traks = [];
      this.campusTracks.forEach(function (item) {
        item.traks.forEach(function (item) {
          traks.push(item);
        });
      });
      return traks.reverse();
      /* } */
    },
  },
  watch: {
    degLvl() {
      /* if (this.degLvlSet > 1) {
        this.program = { trak: "", txt: "", campuses: [] };
      }
      ++this.degLvlSet; */
    },
    collAttSearch1(val) {
      let fieldName = "collAtt1";
      val &&
        this.collAttDoSearch1 &&
        val !== this.collAttSearch1Initial &&
        this.queryCollegeSelections(val, fieldName);
    },
    collAttSearch2(val) {
      let fieldName = "collAtt2";
      val &&
        this.collAttDoSearch2 &&
        val !== this.collAttSearch2Initial &&
        this.queryCollegeSelections(val, fieldName);
    },
    collAttSearch3(val) {
      let fieldName = "collAtt3";
      val &&
        this.collAttDoSearch3 &&
        val !== this.collAttSearch3Initial &&
        this.queryCollegeSelections(val, fieldName);
    },
    collAttSearch4(val) {
      let fieldName = "collAtt4";
      val &&
        this.collAttDoSearch4 &&
        val !== this.collAttSearch4Initial &&
        this.queryCollegeSelections(val, fieldName);
    },
    hsSearch(val) {
      val &&
        this.hsDoSearch &&
        val !== this.hsSearchInitial &&
        this.queryHsSelections(val);
    },
    program(newProgram, oldProgram) {
      let self = this;
      //Find Degree Level From Program
      for (let i = 0; i < this.campusTracks.length; i++) {
        let thisProg = this.campusTracks[i];
        for (let i = 0; i < thisProg.traks.length; i++) {
          if (thisProg.traks[i].trak === newProgram.trak) {
            if (thisProg.prog === "GRAD") {
              self.degLvl = "Graduate";
            } else if (thisProg.prog === "UNDG") {
              self.degLvl = "Bachelors";
            } else if (thisProg.prog === "CERT") {
              self.degLvl = "Certificate";
            } else if (thisProg.prog === "GND") {
              self.degLvl = "Non-Degree Grad";
            }
          }
        }
      }
      if (this.programSet) {
        this.classTypeValue = "";
        if (oldProgram && oldProgram.trak && oldProgram.trak !== "") {
          // reset the campus back to blank if the program is changed, but not on the initial load
          this.campus = "";
          this.concentration = "";
          this.teachCertType = "";
          this.teachCertName = "";
        }
      }

      self.siteList = [];
      self.program.campuses.map(function (val) {
        self.siteList.push(val.campus);
      });

      if (self.siteList.length === 1) {
        if (
          self.siteList[0] == "Online" ||
          self.siteList[0] == "Virtual Classroom"
        ) {
          self.campus = self.siteList[0];
        }
      }

      if (this.campus === "Virtual Classroom") {
        this.classTypeValue = "Virtual Classroom";
      } else if (this.campus === "Online") {
        this.classTypeValue = "Online";
      } else if (this.campus) {
        this.classTypeValue = "Face-To-Face";
      } else {
        // if the site list contains neither Online nor Virtual Classroom (MSW program on May 18 2020), set the class type to Face-To-Face
        if (
          !self.siteList.includes("Online") &&
          !self.siteList.includes("Virtual Classroom")
        ) {
          this.classTypeValue = "Face-To-Face";
        } else {
          this.classTypeValue = "";
        }
      }
      //console.log(newProgram.nonDegree)
      //console.log(self)
      if(newProgram.nonDegree === true){
        this.graduateFromSAU = "N"
        //console.log("should be N:"+this.graduateFromSAU)
      }else{
        this.graduateFromSAU = "Y"
        //console.log("should be Y:"+this.graduateFromSAU)
      }
      this.$refs.form.resetValidation();
      this.programSet = true;
    }, 
  },
  methods: {
    checkHsNotListed() {
      this.hsNotListed = true;
      this.highSchoolNumber = 0;
    },
    checkColl1NotListed() {
      this.coll1NotListed = true;
      this.collAttCode1 = 0;
    },
    checkColl2NotListed() {
      this.coll2NotListed = true;
      this.collAttCode2 = 0;
    },
    checkColl3NotListed() {
      this.coll3NotListed = true;
      this.collAttCode3 = 0;
    },
    checkColl4NotListed() {
      this.coll4NotListed = true;
      this.collAttCode4 = 0;
    },
    classTypeChange() {
      if (this.classTypeValue === "Virtual Classroom") {
        this.campus = "Virtual Classroom";
      } else if (this.classTypeValue === "Online") {
        this.campus = "Online";
      } else if (this.classTypeValue === "Face-To-Face") {
        this.campus = "";
      } else {
        this.campus = "";
      }
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("valid", { step: 1, result: true });
      } else {
        this.$emit("valid", { step: 1, result: false });
      }
    },
    queryCollegeSelections(v, fieldName) {
      if (fieldName === "collAtt1") {
        this.collAttLoading1 = true;
      }
      if (fieldName === "collAtt2") {
        this.collAttLoading2 = true;
      }
      if (fieldName === "collAtt3") {
        this.collAttLoading3 = true;
      }
      if (fieldName === "collAtt4") {
        this.collAttLoading4 = true;
      }
      if (v.length > 3) {
        // replace colleges with pull data
        setTimeout(() => {
          if (
            (fieldName === "collAtt1" && v === this.collAttSearch1) ||
            (fieldName === "collAtt2" && v === this.collAttSearch2) ||
            (fieldName === "collAtt3" && v === this.collAttSearch3) ||
            (fieldName === "collAtt4" && v === this.collAttSearch4)
          ) {
            this.searchColleges({ searchString: v }).then(() => {
              if (fieldName === "collAtt1") {
                this.collAttList1 = [...this.collegeSearchResult];
              } else if (fieldName === "collAtt2") {
                this.collAttList2 = [...this.collegeSearchResult];
              } else if (fieldName === "collAtt3") {
                this.collAttList3 = [...this.collegeSearchResult];
              } else if (fieldName === "collAtt4") {
                this.collAttList4 = [...this.collegeSearchResult];
              }
              this.collAttLoading1 = false;
              this.collAttLoading2 = false;
              this.collAttLoading3 = false;
              this.collAttLoading4 = false;
            });
          }
        }, 500);
      }
    },
    queryHsSelections(v) {
      if (v.length > 3) {
        this.hsSearchLoading = true;
        setTimeout(() => {
          // only send the search to the server if this search string isn't out of date
          if (v === this.hsSearch) {
            this.searchHighSchools({ searchString: v }).then(() => {
              this.filteredHs = this.highSchoolSearchResult;
              this.hsSearchLoading = false;
            });
          }
        }, 500);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    hsChosen(value) {
      let isGood = false;
      if (value) {
        let splitVals = value.split("^");
        if (splitVals && splitVals.length >= 5) {
          this.highSchoolNumber = splitVals[0];
          this.hsName = splitVals[1];
          this.hsCity = splitVals[2];
          this.hsState = splitVals[3];
          this.hsCountry = splitVals[4];
          this.forceHsAutoComp();
          isGood = true;
        }
      }
      if (!isGood) {
        this.highSchoolNumber = "";
        this.hsName = "";
        this.hsCity = "";
        this.hsState = "";
        this.hsCountry = "";
      }
    },
    collAtt1Chosen(value) {
      this.collegeChosen(1, value);
    },
    collAtt2Chosen(value) {
      this.collegeChosen(2, value);
    },
    collAtt3Chosen(value) {
      this.collegeChosen(3, value);
    },
    collAtt4Chosen(value) {
      this.collegeChosen(4, value);
    },
    collegeChosen(index, value) {
      let isGood = false;
      if (value) {
        let splitVals = value.split("^");
        if (splitVals && splitVals.length >= 4) {
          this["collAttCode" + index] = splitVals[0];
          this["collAttName" + index] = splitVals[1];
          this.forceCollegeAutoComp(index);
          isGood = true;
        }
      }
      if (!isGood) {
        this["collAttCode" + index] = "";
        this["collAttName" + index] = "";
      }
    },
    forceHsAutoComp() {
      if (this.highSchoolNumber && this.highSchoolNumber != 0) {
        let valueString = `${this.highSchoolNumber}^${this.hsName}^${this.hsCity}^${this.hsState}^${this.hsCountry}`;
        let textString = `${this.hsName} - ${this.hsCity}, ${this.hsState}`;
        let fakeSearchResult = { value: valueString, text: textString };
        this.filteredHs = [fakeSearchResult];
        this.hsSearchInitial = textString;
        this.hsSelection = valueString;
      } else if (this.highSchoolNumber === 0 && this.hsName) {
        this.checkHsNotListed();
      }
    },
    forceCollegeAutoComp(collAttIndex) {
      //console.log(this["collAttCode" + collAttIndex])
      if (
        this["collAttCode" + collAttIndex] &&
        this["collAttCode" + collAttIndex] != 0
      ) {
        let collAttValueString = `${this["collAttCode" + collAttIndex]}^${
          this["collAttName" + collAttIndex]
        }^ZZ^City`;
        let fakeCollAttSearchResult = {
          val: collAttValueString,
          txt: this["collAttName" + collAttIndex],
        };
        this["collAttList" + collAttIndex] = [fakeCollAttSearchResult];
        this["collAttSearch" + collAttIndex + "Initial"] =
          this["collAttName" + collAttIndex];
        this["collAttName" + collAttIndex + "Selection"] = collAttValueString;
      } else if (
        this["collAttCode" + collAttIndex] === 0 &&
        this["collAttName" + collAttIndex]
      ) {
        this["checkColl" + collAttIndex + "NotListed"]();
      }
    },
    onFormInit() {
      this.forceHsAutoComp();
      this.forceCollegeAutoComp(1);
      this.forceCollegeAutoComp(2);
      this.forceCollegeAutoComp(3);
      this.forceCollegeAutoComp(4);
    },
    ...mapMutations(["updateApplicationDataProps", "setCurrentStep"]),
    ...mapActions([
      "saveToServer",
      "loadCampusesAndTracks",
      "searchHighSchools",
      "searchColleges",
      "loadTrackInfo",
    ]),
  },
};
</script>
