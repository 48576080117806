<template>
  <div class="globalappcontainer">
    <main class="globalapp border-radius-0">
      <v-row v-if="!dataLoaded" class="fill-height" align-content="center" justify="center">
        <v-col cols="2">
          <v-progress-linear color="primary" indeterminate rounded></v-progress-linear>
        </v-col>
      </v-row>
      <v-stepper :class="{hide:!dataLoaded}" v-model="crntStep" alt-labels>
        <v-stepper-header>
          <v-stepper-step
            @click="beforeStepChange()"
            step="1"
            :editable="editables[0].editable"
            :complete="stepCompletes[0].stepComplete"
            :rules="stepRules[0].stepRule"
            edit-icon="mdi-check"
            error-icon="mdi-pencil"
            :color="stepColors[0].color"
          >Academic</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            @click="beforeStepChange()"
            step="2"
            :editable="editables[1].editable"
            :complete="stepCompletes[1].stepComplete"
            :rules="stepRules[1].stepRule"
            edit-icon="mdi-check"
            error-icon="mdi-pencil"
            :color="stepColors[1].color"
          >Personal</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            @click="beforeStepChange()"
            step="3"
            :editable="editables[2].editable"
            :complete="stepCompletes[2].stepComplete"
            :rules="stepRules[2].stepRule"
            edit-icon="mdi-check"
            error-icon="mdi-pencil"
            :color="stepColors[2].color"
          >Background</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            @click="beforeStepChange()"
            step="4"
            :editable="editables[3].editable"
            :complete="stepCompletes[3].stepComplete"
            :rules="stepRules[3].stepRule"
            edit-icon="mdi-check"
            error-icon="mdi-pencil"
            :color="stepColors[3].color"
          >Agreement</v-stepper-step>
        </v-stepper-header>

        <v-progress-linear v-model="stepProgress" color="primary"></v-progress-linear>
        

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="flex-items-center flex-items-right-lg">
            <v-btn text class="mr-3 pull-right-lg" @click="saveAndExit()">
              <v-icon small class="mr-1">mdi-cloud-upload</v-icon>Save and Exit
            </v-btn>
            </v-row>
            <GlobalAppStep1 ref="globalAppStep1" @valid="checkStepValid"></GlobalAppStep1>

            

            
            <v-btn class="pull-right" color="primary" @click="beforeStepChange(); crntStep = 2;">Continue</v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row class="flex-items-center flex-items-right-lg">
            <v-btn text class="mr-3 pull-right" @click="saveAndExit()">
              <v-icon small class="mr-1">mdi-cloud-upload</v-icon>Save and Exit
            </v-btn>
            </v-row>
            <GlobalAppStep2 ref="globalAppStep2" @valid="checkStepValid"></GlobalAppStep2>

            <v-btn class="mr-3" color="primary" @click="beforeStepChange(); crntStep = 1;">Back</v-btn>
            
                        <v-btn class="pull-right" color="primary" @click="beforeStepChange(); crntStep = 3;">Continue</v-btn>

          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row class="flex-items-center flex-items-right-lg">
            <v-btn text class="mr-3 pull-right" @click="saveAndExit()">
              <v-icon small class="mr-1">mdi-cloud-upload</v-icon>Save and Exit
            </v-btn>
            </v-row>
            <GlobalAppStep3 ref="globalAppStep3" @valid="checkStepValid"></GlobalAppStep3>

            <v-btn class="mr-3" color="primary" @click="beforeStepChange(); crntStep = 2;">Back</v-btn>
            
                        <v-btn class="pull-right" color="primary" @click="beforeStepChange(); crntStep = 4;">Continue</v-btn>

          </v-stepper-content>
          <v-stepper-content step="4">
            <v-row class="flex-items-center flex-items-right-lg">
            <v-btn text class="mr-3 pull-right" @click="saveAndExit()">
              <v-icon small class="mr-1">mdi-cloud-upload</v-icon>Save and Exit
            </v-btn>
            </v-row>
            <GlobalAppStep4 ref="globalAppStep4" @valid="checkStepValid"></GlobalAppStep4>
            <v-row
              v-if="stepCompletes[0].stepComplete === false || stepCompletes[1].stepComplete === false || stepCompletes[2].stepComplete === false"
            >
              <v-col class="pb-0">
                <p
                  class="mb-0 error--text"
                >The listed steps below need to be completed to submit your application.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="1" v-if="stepCompletes[0].stepComplete === false">
                <a class="error--text" @click="crntStep = 1;" href="#">Step 1</a>
              </v-col>
              <v-col cols="12" md="1" v-if="stepCompletes[1].stepComplete === false">
                <a class="error--text" @click="crntStep = 2;" href="#">Step 2</a>
              </v-col>
              <v-col cols="12" md="1" v-if="stepCompletes[2].stepComplete === false">
                <a class="error--text" @click="crntStep = 3;" href="#">Step 3</a>
              </v-col>
            </v-row>
            <v-form ref="payment">
              <v-card
                class="my-4 px-6 py-4 mb-10"
                outlined
                v-if="formCompleted === true && trackRequirements.applicationPayment"
              >
                <h2>Application Payment</h2>
                <p>If you have any questions regarding the application fee, please contact an admissions representative.</p>
                <p>
                  <strong>Application Fee:</strong> $30
                </p>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-radio-group :rules="required" label="Payment Method" v-model="paymentMethod">
                      <v-radio label="Credit/Debit Card" value="creditcard"></v-radio>
                      <v-radio label="Electronic Check" value="electroniccheck"></v-radio>
                      <v-radio label="Promo Code" value="promocode"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="paymentMethod === 'promocode'">
                  <v-col cols="12" md="6">
                    <v-text-field
                      validate-on-blur
                      v-model="promoCode"
                      :rules="required"
                      clearable
                      label="Promo Code"
                      name="promoCode"
                      type="text"
                      maxlength="24"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <span
                  v-if="paymentMethod === 'creditcard' || paymentMethod === 'bankwithdraw' || paymentMethod === 'electroniccheck'"
                >
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-checkbox
                        v-model="copyInfo"
                        label="Is your payment information the same as your personal information?"
                        @change="copyPersonalToCard();"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="fnamePayment"
                        :rules="required"
                        clearable
                        label="First Name"
                        name="fname"
                        type="text"
                        maxlength="24"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="lnamePayment"
                        :rules="required"
                        clearable
                        label="Last Name"
                        name="lname"
                        type="text"
                        maxlength="32"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="10">
                      <v-text-field
                        validate-on-blur
                        v-model="address1Payment"
                        :rules="required"
                        clearable
                        label="Current Address"
                        name="address1Payment"
                        type="text"
                        maxlength="32"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-checkbox v-model="addLineTwo" label="Add Line 2"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" v-if="addLineTwo || address2Payment">
                      <v-text-field
                        validate-on-blur
                        v-model="address2Payment"
                        :rules="required"
                        clearable
                        label="Line 2"
                        name="address2"
                        type="text"
                        maxlength="32"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-model="countryPayment"
                        :rules="required"
                        :items="countries"
                        item-text="txt"
                        item-value="ctry"
                        label="Country"
                        editable
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        validate-on-blur
                        v-model="cityPayment"
                        :rules="required"
                        clearable
                        label="City"
                        name="city"
                        type="text"
                        maxlength="32"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="countryPayment === 'USA'">
                      <v-autocomplete
                        v-model="statePayment"
                        :items="stateChoices"
                        item-text="txt"
                        item-value="st"
                        label="State"
                        editable
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" v-if="countryPayment != '' && countryPayment != 'USA'">
                      <v-text-field
                        validate-on-blur
                        v-model="provincePayment"
                        :rules="required"
                        clearable
                        label="Province"
                        name="Province"
                        type="text"
                        maxlength="45"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      v-if="countryPayment === 'USA' || countryPayment === 'CDN'"
                    >
                      <v-text-field
                        validate-on-blur
                        v-model="zipPayment"
                        :rules="required"
                        clearable
                        label="Zip"
                        name="zip"
                        type="text"
                        maxlength="10"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="payerEmail"
                        :rules="emailRules"
                        clearable
                        label="Email"
                        name="payerEmail"
                        type="text"
                        maxlength="50"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="payerPhone"
                        :rules="mobileRules"
                        clearable
                        label="Phone"
                        name="payerPhone"
                        type="text"
                        maxlength="12"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </span>
                <span v-if="paymentMethod === 'creditcard'">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        validate-on-blur
                        v-model="creditCardNumber"
                        :rules="cardNumber"
                        clearable
                        label="Credit/Debit Card Number"
                        name="creditCardNumber"
                        type="text"
                        maxlength="16"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="4">
                      <v-autocomplete
                        :rules="required"
                        v-model="expirationMonth"
                        :items="months"
                        label="Exp. Month"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-autocomplete
                        :rules="required"
                        v-model="expirationYear"
                        :items="cardYearRange"
                        label="Exp.Year"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        validate-on-blur
                        v-model="cardVerification"
                        :rules="cardVerificationNumber"
                        clearable
                        label="Verification Number (CVV)"
                        name="cardVerification"
                        type="text"
                        maxlength="3"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </span>
                <span
                  v-if="paymentMethod === 'bankwithdraw' || paymentMethod === 'electroniccheck'"
                >
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        validate-on-blur
                        v-model="routingNumber"
                        :rules="routingNumberValid"
                        clearable
                        label="Routing Number"
                        name="routingNumber"
                        type="text"
                        maxlength="9"
                        @change="trimRoutingNum();"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        validate-on-blur
                        v-model="accountNumber"
                        :rules="accountNumberValid"
                        clearable
                        label="Account Number"
                        name="accountNumber"
                        type="text"
                        maxlength="20"
                        @change="trimAccountNum();"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </span>
                <span v-if="paymentMethod === 'electroniccheck'">
                  <v-row>
                    <v-col cols="6" md="3">
                      <v-text-field
                        validate-on-blur
                        v-model="checkNumber"
                        :rules="checkNumberValid"
                        clearable
                        label="Check Number"
                        name="checkNumber"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-text-field
                        validate-on-blur
                        v-model="checkMemo"
                        :rules="required"
                        label="Check Memo"
                        name="checkMemo"
                        maxlength="120"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </span>
              </v-card>
            </v-form>
            <v-btn class="mr-3" color="primary" @click="beforeStepChange(); crntStep = 3;">Back</v-btn>
            <v-btn class="mr-3" :disabled="disableSubmit" @click="submitForm()">Submit</v-btn>
            
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import GlobalAppStep1 from "./GlobalAppStep1";
import GlobalAppStep2 from "./GlobalAppStep2";
import GlobalAppStep3 from "./GlobalAppStep3";
import GlobalAppStep4 from "./GlobalAppStep4";
import { createNamespacedHelpers } from "vuex";
import { cardYearRange } from "@/utils";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "globalApplication"
);
export default {
  name: "GlobalAppContainer",
  components: {
    GlobalAppStep4,
    GlobalAppStep3,
    GlobalAppStep2,
    GlobalAppStep1
  },
  data() {
    return {
      dataLoaded: false,
      stepProgress: 25,
      crntStep: 1,
      prevStep: "",
      formCompleted: false,
      disableSubmit: true,
      paymentMethod: "",
      copyInfo: false,
      fnamePayment: "",
      lnamePayment: "",
      address1Payment: "",
      addLineTwo: "",
      address2Payment: "",
      countryPayment: "",
      statePayment: "",
      cityPayment: "",
      provincePayment: "",
      zipPayment: "",
      payerEmail: "",
      payerPhone: "",
      creditCardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cardVerification: "",
      routingNumber: "",
      accountNumber: "",
      checkNumber: "",
      checkMemo: "",
      promoCode: "",
      promocodes: [
        "JB19",
        "AH16",
        "PS14",
        "DT13",
        "NS11",
        "KR20",
        "BB12",
        "LU84",
        "TA20",
        "IB21",
        "MAC22",
        "BR24"
      ],
      //validation
      required: [v => !!v || "This field is required."],
      emailRules: [
        v => !!v || "This field is required",
        v => /.+@.+\..+/.test(v) || "A valid email address is required"
      ],
      cardNumber: [
        v => !!v || "This field is required.",
        v =>
          /\d{4}-?\d{4}-?\d{4}-?\d{4}/.test(v) || "Must be a valid card number."
      ],
      cardVerificationNumber: [
        v => !!v || "This field is required.",
        v => /\d{3}/.test(v) || "Must be a valid cvv number."
      ],
      routingNumberValid: [
        v => !!v || "This field is required.",
        v => /\d{9}/.test(v) || "Must be a valid routing number."
      ],
      accountNumberValid: [
        v => !!v || "This field is required.",
        v => /\d{6,20}/.test(v) || "Must be a valid account number."
      ],
      checkNumberValid: [
        v => !!v || "This field is required.",
        v => /\d{1,6}/.test(v) || "Must be a valid check number."
      ],

      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ],
      cardYearRange: cardYearRange(),
      states: ["MI", "FL", "CO"],

      stepRules: [
        { stepRule: [() => true] },
        { stepRule: [() => true] },
        { stepRule: [() => true] },
        { stepRule: [() => true] }
      ],
      stepCompletes: [
        { stepComplete: false },
        { stepComplete: false },
        { stepComplete: false },
        { stepComplete: false }
      ],
      stepColors: [
        { color: "grey" },
        { color: "grey" },
        { color: "grey" },
        { color: "grey" }
      ],
      editables: [
        { editable: true },
        { editable: false },
        { editable: false },
        { editable: false }
      ],
      mobileRules: [
      v => !!v || "This field is required",
      v =>
        /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(v) ||
        "A valid 10 digit phone number is required"
    ]
    };
  },
  computed: {
    ...mapState([
      "applicData",
      "campusTracks",
      "trackRequirements",
      "highSchoolSearchResult",
      "currentStep",
      "collegeSearchResult",
      "stateChoices",
      "countries",
      "degLvlQueryArgument",
      "applicationID",
      "emailKey"
    ])
  },
  methods: {
    trimAccountNum() {
      this.accountNumber = this.accountNumber.replace(/[^0-9]/g, "");
    },
    trimRoutingNum() {
      this.routingNumber = this.routingNumber.replace(/[^0-9]/g, "");
    },
    updateStepProgress() {
      this.stepProgress = (this.crntStep / 4) * 100;
//      console.log(this.stepProgress);
    },
    submitForm() {
      if (this.trackRequirements.applicationPayment) {
        if (this.$refs.payment.validate()) {
          this.submitPayment();
        }
      } else {
        this.finalizeApplication("");
      }
    },
    beforeStepChange() {
      this.prevStep = parseInt(this.crntStep);
      this.saveToServer();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    checkStepValid: function(value) {
      if (value.result === true) {
        this.stepRules[value.step - 1].stepRule = [() => true];
        this.stepCompletes[value.step - 1].stepComplete = true;
        this.stepColors[value.step - 1].color = "success";
      } else {
        this.stepRules[value.step - 1].stepRule = [() => false];
        this.stepCompletes[value.step - 1].stepComplete = false;
        this.stepColors[value.step - 1].color = "error";
      }

      for (var item in this.stepCompletes) {
        if (!this.stepCompletes[item].stepComplete) {
          this.formCompleted = false;
          this.disableSubmit = true;
          break;
        } else {
          this.formCompleted = true;
          // the Submit button is enabled here because the button's behavior will adjust depending on whether the fee is required
          this.disableSubmit = false;
        }
      }
    },
    saveAndExit() {
      this.saveToServer().then(() => {
        this.setApplicationIdAndEmail({
          appId: "",
          emailKey: ""
        });
        this.$router.push("/Exit");
      });
    },
    finalizeApplication(promoCode) {
      return this.finishApplication({
        promoCode: promoCode,
        payMethod:
          this.trackRequirements.applicationPayment && promoCode === ""
            ? "ONL"
            : "",
        ssNo: this.applicData.ssNo,
        agreeValues: this.applicData.agreeValues
      })
        .then(() => {
          this.setApplicationIdAndEmail({
            appId: "",
            emailKey: ""
          });
          this.$router.push("/Finish");
          //GTM Successly Submit Event
            window.dataLayer.push({
            'submit': 'success',
            'event': 'virtualFormSubmit'
            });
        })
        .catch(err => {
          if (err.response && err.response.data) {
//            console.log(err);
            //GTM Fail Submit Event
            window.dataLayer.push({
            'submit': 'fail',
            'event': 'virtualFormSubmit'
            });
            Vue.rollbar.error(err);
            this.$emit("modalMessage", {
              show: true,
              header: "Application Error",
              message:
                "There was an error submitting your finished application.",
              color: "error"
            });
          }
        });
    },
    submitPayment() {
      if (this.paymentMethod === "promocode") {
        let checkPromo = this.promocodes.includes(this.promoCode);
        if (checkPromo) {
          this.finalizeApplication(this.promoCode);
        } else {
          this.$emit("modalMessage", {
            show: true,
            header: "Promo Code Error",
            message: "Your promotional code was not found.",
            color: "error"
          });
        }
      } else {
        this.payFee({
          firstName: this.fnamePayment,
          lastName: this.lnamePayment,
          payerEmail: this.payerEmail,
          phone: this.payerPhone,
          currentAddress: this.address1Payment,
          line2: this.addLineTwo,
          city: this.cityPayment,
          state: this.statePayment,
          zip: this.zipPayment,
          country: this.countryPayment,
          province: this.provincePayment,
          creditCardNumber: this.creditCardNumber,
          expirationMonth: this.expirationMonth,
          expirationYear: "20" + this.expirationYear,
          cardVerification: this.cardVerification,
          routingNumber: this.routingNumber,
          accountNumber: this.accountNumber,
          checkNumber: this.checkNumber,
          checkMemo: this.checkMemo
        })
          .catch(err => {
            if (err.response && err.response.data) {
//              console.log(err);
              Vue.rollbar.error(err);
              this.$emit("modalMessage", {
                show: true,
                header: "Payment Error",
                message:
                  'There was an error in your payment information. Please try again or call <a href="tel:5177506684">517.750.6684</a>. Additional details: ' + err.response.data,
                color: "error"
              });
              //alert("error message from the server is: " + err.response.data);
            }
            throw err; // prevent the application from submitting after this error
          })
          .then(() => this.finalizeApplication(""));
      }
    },
    copyPersonalToCard() {
      if (this.copyInfo === true) {
        this.fnamePayment = this.applicData.firstName;
        this.lnamePayment = this.applicData.lastName;
        this.address1Payment = this.applicData.currentAddress;
        this.address2Payment = this.applicData.line2;
        this.countryPayment = this.applicData.country;
        this.statePayment = this.applicData.state;
        this.cityPayment = this.applicData.city;
        this.zipPayment = this.applicData.zip;
        this.payerEmail = this.applicData.email;
        this.payerPhone = this.applicData.phone;
      } else {
        this.fnamePayment = "";
        this.lnamePayment = "";
        this.address1Payment = "";
        this.address2Payment = "";
        this.countryPayment = "";
        this.statePayment = "";
        this.cityPayment = "";
        this.zipPayment = "";
        this.payerEmail = "";
        this.payerPhone = "";
      }
    },
    ...mapActions([
      "load",
      "saveToServer",
      "loginWithPassword",
      "forgotPassword",
      "loginWithOTP",
      "startApplication",
      "loadCampusesAndTracks",
      "loadTrackInfo",
      "searchHighSchools",
      "searchColleges",
      "requestNewOTP",
      "loadCountries",
      "payFee",
      "finishApplication"
    ]),
    ...mapMutations([
      "setApplicationIdAndEmail",
      "updateApplicationDataProps",
      "setCurrentStep"
    ])
  },
  watch: {
    crntStep: function() {
      let self = this;
      this.setCurrentStep(self.crntStep)
      //GTM Page Change Event
      window.dataLayer.push({
        'virtualPageURL': 'globalapp-step' + (self.crntStep),
        'event': 'pageChange'
        });
      if (this.prevStep === 1) {
        this.$refs.globalAppStep1.validate();
      }
      if (this.prevStep === 2) {
        this.$refs.globalAppStep2.validate();
        this.editables[1].editable = true;
      }
      if (this.prevStep === 3) {
        this.$refs.globalAppStep3.validate();
        this.editables[2].editable = true;
      }
      if (this.prevStep === 4) {
        this.$refs.globalAppStep4.validate();
        this.editables[3].editable = true;
      }
      this.updateStepProgress();
    }
  },
  mounted() {
    if (typeof this.$api.axios.defaults.headers.common['Authorization'] === 'undefined' || this.$api.axios.defaults.headers.common['Authorization'] === null
            || this.$api.axios.defaults.headers.common['Authorization'].length < 6 || this.applicationID === '' || this.emailKey === '') {
      this.setApplicationIdAndEmail({
        appId: "",
        emailKey: ""
      });
      this.$router.push("/Login");
      return
    }
    this.loadCountries()
      .then(() => this.load())
      .then(() => {
        //console.log(
        //  "the degree level query argument was " + this.degLvlQueryArgument
        //);
        if (this.applicData.track) {
          return this.loadTrackInfo({ track: this.applicData.track });
        }
      })
      .then(() => {
        //Load Step
        if (this.currentStep < 0) {
          this.crntStep = 1;
        } else {
          this.crntStep = this.currentStep;
        }
        if (this.crntStep === 1){
          //GTA intital page view for step
          window.dataLayer.push({
          'virtualPageURL': 'globalapp-step' + (this.crntStep),
          'event': 'pageChange'
          });
        }
      })
      .then(() => {
        this.$refs.globalAppStep1.onFormInit();
      })
      .then(() => {

        if (this.crntStep === 2) {
          this.$refs.globalAppStep1.validate();
          this.editables[1].editable = true;
        }
        if (this.crntStep === 3) {
          this.$refs.globalAppStep1.validate();
          this.$refs.globalAppStep2.validate();
          this.editables[1].editable = true;
          this.editables[2].editable = true;
        }
        if (this.crntStep === 4) {
          this.$refs.globalAppStep3.validate();
          this.$refs.globalAppStep1.validate();
          this.$refs.globalAppStep2.validate();
          this.editables[1].editable = true;
          this.editables[2].editable = true;
          this.editables[3].editable = true;
        }
        //Vue.rollbar.debug('rollbar after loading Container');
      })
      .then(() => {
        this.dataLoaded = true;
      });

    /*
      // only one of these finishApplication test lines is to be run at once...
//      .then(() => this.finishApplication({ promoCode: '', payMethod: 'ONL'}))
//      .then(() => this.finishApplication({ promoCode: 'TEST', payMethod: ''}))
      .then(() => {
        console.log('finished application ');
      })
      .catch((err) => {
        console.log(err);
      })
    */
    /*
            .then(() => {
                return this.loadTrackInfo({ track: 'MSN' });
            })
            .then(() => {
                console.log(this.trackRequirements);
            })
            */
    /*
                //.then( () => {
                    this.updateApplicationDataProps({ promoCode: '3710', payMethod: 'ONL' });
                    this.saveToServer()
                //})
                .then( () => {
                    console.log('save done');
                })
                */
    let timerNumber = setInterval(() => {
      if (this.applicationID !== '' && this.emailKey !== '') {
        this.saveToServer();
      }
      //                function (error) {
      //                  myself.rollbar.error("error saving data on timer",{errorResponse: error})
      //                }
    }, 150000);
    setTimeout(() => {
      clearInterval(timerNumber);
      this.saveToServer()
        .then(() => {
          this.setApplicationIdAndEmail({
            appId: "",
            emailKey: ""
          });
          this.$router.push("/Login");
        })
        .catch(() => {
          this.setApplicationIdAndEmail({
            appId: "",
            emailKey: ""
          });
          this.$router.push("/Login");
        });
    }, 6900000); // 115 minutes
    /*
      // to test: use a first name of Test, test credit card number is 5454545454545454, test routing number is 324377516, test account number is 1234567890
      this.payFee({
        firstName: 'Test',
        lastName: 'Test',
        payerEmail: 'ratherto@arbor.edu',
        phone: '517-750-6633',
        currentAddress: '106 E Main St',
        line2: 'Station 99',
        city: 'Spring Arbor',
        state: 'MI',
        zip: '49283',
        country: 'USA',
        province: '',
        creditCardNumber: '5454545454545454',
        expirationMonth: '01',
        expirationYear: '2025',
        cardVerification: '111',
        //creditCardNumber: '',
        //expirationMonth: '',
        //expirationYear: '',
        //cardVerification: '',
        routingNumber: '',
        accountNumber: '',
        //routingNumber: '324377516',
        //accountNumber: '1234567890',
        checkNumber: '',
        checkMemo: ''
      })
      .then((dat) => {
        console.log('paid fee');
        console.log(dat);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.log(err);
          this.$emit("modalMessage", { show: true, header: "error", message: "error message from the server is: " + err.response.data, color: "error" });
          //alert('error message from the server is: ' + err.response.data);
        }
      });
    */
    /*
                .then( () => {
                    return this.loginWithOTP({ oneTimePassword: '' });
                })
                .then( (dat) => {
                    console.log('login with OTP done: ');
                    console.log(dat);
                })
                */
    /*
                .then( () => {
                    this.forgotPassword({ emailAddress: '', cell: '555-555-5555' });
                })
                .then( () => {
                    console.log('forgotten password returned');
                })
                */
    /* below was some test code for requesting a new one time password
                    a real then() handler needs to check for whether dat.length is 0 (wrong password or email)
                      or whether isDone is Y (trying to log in to a submitted application)
                */
    /*
                .then( () => {
                    return this.requestNewOTP({ emailAddress: 'mpatters21@yahoo.com', password: 'nottherealpassword'});
                })
                */
    /*
                .then( () => {
                    return this.startApplication(
                        {
                            email: 'reid.atherton@arbor.edu',
                            firstName: 'Reid',
                            cell: '517-555-8765',
                            textOptIn: 'Y',
                            newPassword: 'passpa',
                            lastName: 'Atherton'
                        }
                    );
                })
                .then( (dat) => {
                    console.log('application start returned');
                    console.log(dat);
                })
                */

    /*
      .then(() => {
        return this.searchHighSchools({ searchString: "Taft" });
      })

      .then(() => {
        console.log(this.highSchoolSearchResult);
      })

      .then(() => {
        return this.searchColleges({ searchString: "Taft" });
      })

      .then(() => {
        console.log(this.collegeSearchResult);
      });
       */
  }
};
</script>

