import { render, staticRenderFns } from "./GlobalAppStep4.vue?vue&type=template&id=2e6bb5de&scoped=true&"
import script from "./GlobalAppStep4.vue?vue&type=script&lang=js&"
export * from "./GlobalAppStep4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6bb5de",
  null
  
)

export default component.exports